<template>
  <div>
    <div class="control">
      <div style="padding-top: 10px" v-if="content.motivation.show">
        <div
          
          style="
                            padding: 0;
                            margin: 0;
                            height: auto;
                            min-height: auto;
                          "
          v-html="htmlData(content.motivation.content)"
        ></div>
      </div>
      <div style="z-index: 9; padding-top: 10px" v-if="content.horizontal">
        <v-slide-group
          class="slide_listing"
          v-model="active_faq_tab"
          center-active
        >
          <v-slide-item
            v-for="(faq, faqIndex) in content.content"
            :key="faqIndex"
            v-slot="{ active, toggle }"
          >
            <v-btn
              :input-value="active_faq_tab === faqIndex"
              active-class="active_listing_tab"
              class="mx-2"
              style="border-radius: 12px; border: none"
              :style="
                `background:${
                  active_faq_tab === faqIndex
                    ? content.horizontal_active_background
                    : content.horizontal_background
                };color:${
                  active_faq_tab === faqIndex
                    ? content.horizontal_active_color
                    : content.horizontal_color
                };`
              "
              depressed
              outlined
              small
              @click="
                toggle;
                scrollToTitleFaq(faqIndex);
              "
            >
              {{ faq.title }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </div>
      <div
        v-for="(faq, faqIndex) in content.content"
        :key="faqIndex"
        style="margin-top: 10px"
      >
        <div class="faq__title" :ref="`faq${faqIndex}`">
          {{ faq.title }}
        </div>
        <VueFaqAccordion
          style="padding: 0"
          :items="faq.content"
          questionProperty="question"
          answerProperty="answer"
          :activeColor="content.color"
          borderColor="#D4E1E1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import VueFaqAccordion from "vue-faq-accordion";
export default {
  name: "typeFaqPreview",
  computed: {
    ...mapState(["landing"]),
    dateToday: function() {
      return moment().format("DD.MM.YYYY");
    },
  },
  components: {
    VueFaqAccordion,
  },
  data() {
    return {
      active_faq_tab: 0,
      datenow: null,
    };
  },
  props: ["content"],
  mounted() {
    this.timeNow();
  },
  methods: {
    timeNow() {
      this.datenow = moment().format("HH:mm");
      setInterval(() => {
        this.datenow = moment().format("HH:mm");
      }, 1000);
    },
    scrollToTitleFaq(index) {
      this.active_faq_tab = index;
      let preview = document.getElementsByClassName("canvas");
      if (index === 0) {
        preview[0].scrollTop = 0;
      } else {
        preview[0].scrollTop = this.$refs[`faq${index}`][0].offsetTop + 25;
      }
    },
    htmlData(data, type) {
      if(data){

      const weatherInd = data.split(" ").indexOf(":weather:");
      let result = data.split(" ");
      if (weatherInd && this.landing.advanced.global.show_weather) {
        const newArr = result;
        newArr[weatherInd] =
          this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
            0
          ) + " ℃";
        result = newArr;
      }
      const timeInd = data.split(" ").indexOf(":time:");
      if (timeInd) {
        const newArr = result;
        newArr[timeInd] = this.datenow;
        result = newArr;
      }
      const dateInd = data.split(" ").indexOf(":date:");
      if (dateInd) {
        const newArr = result;
        newArr[dateInd] = this.dateToday;
        result = newArr;
      }
      const user_name = data.split(" ").indexOf(":name:");
      if (
        user_name &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_name] = "Agent";
        result = newArr;
      }
      const user_surname = data.split(" ").indexOf(":surname:");
      if (
        user_surname &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_surname] = "Smith";
        result = newArr;
      }
      const user_birthday = data.split(" ").indexOf(":birthday:");
      if (
        user_surname &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_birthday] = "24.03.1999";
        result = newArr;
      }
      const user_room = data.split(" ").indexOf(":room:");
      if (
        user_surname &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_room] = "707";
        result = newArr;
      }

      const user_auth = data.split(" ").indexOf(":auth:");
      if (user_auth && this.landing.advanced.global.auth && !type) {
        const newArr = result;
        newArr[user_auth] = `<a href="#">Login</a>`;
        result = newArr;
      }
      const user_logout = data.split(" ").indexOf(":logout:");
      if (user_logout && this.landing.advanced.global.auth && !type) {
        const newArr = result;
        newArr[user_logout] = `<a href="#">Logout</a>`;
        result = newArr;
      }
      return result.join(" ");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
