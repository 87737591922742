import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"control"},[(_vm.content.motivation.show)?_c('div',{staticStyle:{"padding-top":"10px"}},[_c('div',{staticStyle:{"padding":"0","margin":"0","height":"auto","min-height":"auto"},domProps:{"innerHTML":_vm._s(_vm.htmlData(_vm.content.motivation.content))}})]):_vm._e(),(_vm.content.horizontal)?_c('div',{staticStyle:{"z-index":"9","padding-top":"10px"}},[_c(VSlideGroup,{staticClass:"slide_listing",attrs:{"center-active":""},model:{value:(_vm.active_faq_tab),callback:function ($$v) {_vm.active_faq_tab=$$v},expression:"active_faq_tab"}},_vm._l((_vm.content.content),function(faq,faqIndex){return _c(VSlideItem,{key:faqIndex,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c(VBtn,{staticClass:"mx-2",staticStyle:{"border-radius":"12px","border":"none"},style:(("background:" + (_vm.active_faq_tab === faqIndex
                  ? _vm.content.horizontal_active_background
                  : _vm.content.horizontal_background) + ";color:" + (_vm.active_faq_tab === faqIndex
                  ? _vm.content.horizontal_active_color
                  : _vm.content.horizontal_color) + ";")),attrs:{"input-value":_vm.active_faq_tab === faqIndex,"active-class":"active_listing_tab","depressed":"","outlined":"","small":""},on:{"click":function($event){toggle;
              _vm.scrollToTitleFaq(faqIndex);}}},[_vm._v(" "+_vm._s(faq.title)+" ")])]}}],null,true)})}),1)],1):_vm._e(),_vm._l((_vm.content.content),function(faq,faqIndex){return _c('div',{key:faqIndex,staticStyle:{"margin-top":"10px"}},[_c('div',{ref:("faq" + faqIndex),refInFor:true,staticClass:"faq__title"},[_vm._v(" "+_vm._s(faq.title)+" ")]),_c('VueFaqAccordion',{staticStyle:{"padding":"0"},attrs:{"items":faq.content,"questionProperty":"question","answerProperty":"answer","activeColor":_vm.content.color,"borderColor":"#D4E1E1"}})],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }